.ArticleMeta_meta {
  padding: 18px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
  align-items: baseline;
  font-size: 11px;
  text-transform: uppercase;
  width: calc(100% - 50px);
  max-width: 590px;
}

.ArticleMeta_card.ArticleMeta_meta {
  padding: 18px 20px;
  border-top: solid 1px var(--light-grey);
  width: calc(100% - 40px);
}

.ArticleMeta_browse.ArticleMeta_meta {
  border-top: none;
}

.ArticleMeta_category {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding-right: 0.5em;
  border-right: 1px solid var(--light-grey);
  margin-right: 0.5em;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.ArticleMeta_date {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: var(--charcoal-grey);
}
